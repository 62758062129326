import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['cover', 'photo', 'star']

  connect() { }

  updateCoverPicturePreview() {
    console.log("updating cover picture preview")
    if(this.photoTargets.length > 0) {
      this.coverTarget.src = this.photoTargets[0].src
    }
    this.starTargets.forEach(element => {
      if(!element.classList.contains("hidden")) {
        element.classList.add("hidden")
      }
    });

    this.starTargets[0].classList.remove("hidden")

    console.log(this.photoTargets.length)
  }
}