import { Controller } from "stimulus"

import debounce from "../../common/debounce"


export default class extends Controller {
  static targets = [ "form" ]

  initialize() {
    this.update = debounce(this.update, 400)
  }

  update() {
    this.formTarget.requestSubmit();
  }
}
