import "core-js/stable";
import "regenerator-runtime/runtime";
import toastr from "toastr"
import "./components/momentjs_i18n.js"
import "./components/trix-extensions.js"
require("@rails/actiontext")
import Rails from "@rails/ujs"
import Uploads from "./base/uploads.js"
import Locale from "../../common/locale.js"
import AutoSubmit from "./components/auto-submit.js"
import Charts from "./components/charts.js"
import Maps from "./components/maps.js"
import autoSubmit from "./components/auto-submit.js";
import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import { createConsumer } from "@rails/actioncable"
import debounced from 'debounced'
const application = Application.start()
const context = require.context("../controllers", true, /.js$/)
const common_context = require.context("../../common/controllers", true, /.js$/)
application.load(definitionsFromContext(context))
application.load(definitionsFromContext(common_context))
import $ from "jquery";
window.$ = $

// import Flatpickr
import Flatpickr from 'stimulus-flatpickr'

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")

// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr)

let consumer =  createConsumer()
window.consumer = consumer
debounced.initialize()
StimulusReflex.initialize(application, { consumer })

window.Chartkick = Chartkick;
window.toastr = toastr;

Rails.start()

document.addEventListener('load', () => {
  autoSubmit.initOnce()
})

document.addEventListener('turbo:load', () => {
  Uploads.init()
  Locale.init()
  AutoSubmit.init()
  Charts.init()
  Maps.init()
})

