import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["deselectButton", "selectButton", "checkbox"]

  connect() { }

  selectAll() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = true
      var event = new Event('change');
      checkbox.dispatchEvent(event);
    })

    this.selectButtonTarget.classList.add("hidden")
    this.deselectButtonTarget.classList.remove("hidden")
  }

  deselectAll() {
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false)
    this.deselectButtonTarget.classList.add("hidden")
    this.selectButtonTarget.classList.remove("hidden")
  }
}
