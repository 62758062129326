import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    console.log('connected application controller')
  }

  submit(event) {
    event.currentTarget.closest("form").requestSubmit();
  }

  preventDefault(event) {
    event.preventDefault()
  }

  isModalOrCloseButton(element) {
    const hasClass = (clazz, item) => item && item.classList.contains(clazz)
    return hasClass('modal', element) || hasClass('close', element) || hasClass('close', element.parentNode)
  }

  closeModal(event) {
    if (!this.isModalOrCloseButton(event.target)) {
      return;
    }

    document.querySelectorAll('.modal').forEach((element) => {
      element.parentNode.removeChild(element);
    })
  }
}
