import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "resetPassword",
    "passwordInput",
    "passwordConfirmationInput",
    "smsNotifications",
    "smsCountryCode",
    "smsPhoneNumber",
    "apiKeyContainer",
    "destroyCredentialInput",
    "companyShortCodeInput",
    "companyShortCodeInputWrapper"
  ]

  connect() {
  }

  togglePasswordFields() {
    if (this.resetPasswordTarget.checked) {
      this.passwordInputTarget.disabled             = false
      this.passwordConfirmationInputTarget.disabled = false
    } else {
      this.passwordInputTarget.disabled             = true
      this.passwordConfirmationInputTarget.disabled = true
    }
  }

  toggleSmsFields() {
    if (this.smsNotificationsTarget.checked) {
      this.smsCountryCodeTarget.disabled = false
      this.smsPhoneNumberTarget.disabled = false
    } else {
      this.smsCountryCodeTarget.disabled = true
      this.smsPhoneNumberTarget.disabled = true
    }
  }

  showFareHarbourCredentials() {
    this.companyShortCodeInputWrapperTarget.classList.remove("hidden")
    this.showCredentials()
  }

  showActivBookingsCredentials() {
    this.companyShortCodeInputTarget.value = null
    this.companyShortCodeInputWrapperTarget.classList.add("hidden")
    this.showCredentials()
  }

  showSkeaperCredentials() {
    this.hideCredentials()
    this.companyShortCodeInputTarget.value = null
    this.companyShortCodeInputWrapperTarget.classList.remove("hidden")
  }

  showCredentials() {
    this.apiKeyContainerTarget.classList.remove("hidden")
    this.destroyCredentialInputTarget.value = "false";
  }

  hideCredentials() {
    this.apiKeyContainerTarget.classList.add("hidden")
    this.destroyCredentialInputTarget.value = "true";
  }
}
