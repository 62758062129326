import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.expand()
  }

  expand() {
    if (this.element.scrollHeight > this.element.clientHeight) {
      this.element.style.height = `${this.element.scrollHeight + 16}px`
    }
  }
  resize() {
    if (this.element.scrollHeight != this.element.clientHeight) {
      this.element.style.height = `${this.element.scrollHeight + 16}px`
    }
  }
}
