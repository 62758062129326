import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "toggleStatus",
    "toggleManual",
    "toggleAutomatic",
    "automaticCheckbox",
    "manualCheckbox"
  ]

  connect() { }

  toggleStatusFields() {
    if (this.toggleStatusTarget.checked) {
      this.automaticCheckboxTargets.forEach(checkbox => checkbox.checked = false)
      this.toggleManualTarget.classList.remove("hidden")
      this.toggleAutomaticTarget.classList.add("hidden")
    }
    else {
      this.manualCheckboxTargets.forEach(checkbox => checkbox.checked = false)
      this.toggleManualTarget.classList.add("hidden")
      this.toggleAutomaticTarget.classList.remove("hidden")
    }
  }
}
