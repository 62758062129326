import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [
    "deselectButton",
    "selectButton",
    "currentType",
    "checkbox",
  ]

  connect() {
    let checkbox = this.checkboxTarget.dataset.checkboxgroup
    if (this.buttonFilterController) {
      this.buttonFilterController.selectButtonTargets.forEach(selectButton => {
        if (selectButton.dataset.group == checkbox) {
          selectButton.querySelector("span").textContent = this.checkedBoxCount()
        }
      })
    }
    var checkboxes = this.checkboxTargets
    this.toggleSelectButton(checkboxes.filter(checkbox => checkbox.checked).length == checkboxes.length)
  }

  selectAll() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = true
      var event = new Event('change');
      checkbox.dispatchEvent(event);
    })
    this.toggleSelectButton(true)
  }

  deselectAll() {
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false)
    this.toggleSelectButton(false)
  }

  toggleSelectButton(toggleType) {
    if (toggleType) {
      this.selectButtonTarget.classList.add("hidden")
      this.deselectButtonTarget.classList.remove("hidden")
    } else {
      this.deselectButtonTarget.classList.add("hidden")
      this.selectButtonTarget.classList.remove("hidden")
    }
  }

  reCalculateSelectAll() {
    var checkboxes = this.checkboxTargets
    this.toggleSelectButton(checkboxes.filter(checkbox => checkbox.checked).length == checkboxes.length)
    let checkbox = this.checkboxTarget.dataset.checkboxgroup
    if(this.buttonFilterController && this.buttonFilterController.selectButtonTargets) {
      this.buttonFilterController.selectButtonTargets.forEach(selectButton => {
        if (selectButton.dataset.group == checkbox) {
          selectButton.querySelector("span").textContent = this.checkedBoxCount()
        }
      })
    }
  }

  checkedBoxCount() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked).length
  }

  get buttonFilterController() {
    return this.application.getControllerForElementAndIdentifier(this.element.closest("[data-controller='button-filter']"), "button-filter")
  }
}
