import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    console.log("connected")
    this.expanded = false
  }

  toggle() {
    if(this.expanded) {
      this.containerTarget.classList.remove("open")
      this.expanded = false
      document.getElementById("mobile-main-container").classList.add('overflow-y-scroll')
    } else {
      this.containerTarget.classList.add("open")
      this.expanded = true
      document.getElementById("mobile-main-container").classList.remove('overflow-y-scroll')
    }
  }
}