import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["query", "item"]

  filter(e) {
    if (this.hasItemTarget) {
      if(this.queryTarget.value && this.queryTarget.value.trim().length > 0) {
        this.itemTargets.forEach(item => {
          if(item.dataset.value.toLowerCase().includes(this.queryTarget.value.toLowerCase())) {
            item.classList.remove("hidden")
          } else {
            item.classList.add("hidden")
          }
        })
      } else {
        this.itemTargets.forEach(item => item.classList.remove("hidden"))
      }
    }
  }
}