import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["menu", "responsiveMenu"]
  connect() { }

  toggleMenu() {
    if(this.menuTarget.classList.contains("active")) {
      this.menuTarget.classList.remove("active")
      this.responsiveMenuTarget.classList.remove("border-none")
    } else {
      this.menuTarget.classList.add("active")
      this.responsiveMenuTarget.classList.add("border-none")
    }
  }
}