import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["items"]

  addBlock(event) {
    event.preventDefault()
    let templateId = event.currentTarget.dataset.templateId
    let content = document.getElementById(templateId).innerHTML
    content = content.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.itemsTarget.innerHTML = this.itemsTarget.innerHTML + content
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    let destroyInput = item.querySelector("input[name*='_destroy']")
    if(destroyInput) {
      destroyInput.value = 1
    } else {
      item.remove()
    }
    item.style.display = 'none'
  }
}
