import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tabcontrol", "tab"]

  connect() {
    let selectedTab = this.data.get("active-tab")

    this.activate(selectedTab)
  }

  activate(tab) {
    let itemsToActivate = [this.tabcontrolTargets, this.tabTargets]

    itemsToActivate.forEach(items => {
      items.forEach(element => {
        if(element.dataset.key == tab) {
          element.classList.add("active")
        } else {
          element.classList.remove("active")
        }
      });
    })
  }

  activateTab(e) {
    e.preventDefault()
    let key = e.currentTarget.parentElement.dataset.key || e.currentTarget.dataset.key
    this.activate(key)
  }
}
