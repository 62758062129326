<template>
  <gmap-map :center="location" :zoom="11">
    <gmap-marker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      :draggable="true"
      @dragend="locationChanged"
    >

      <gmap-info-window v-if="untouched">{{ infoLabel }}</gmap-info-window>
    </gmap-marker>
  </gmap-map>
</template>

<script>
  /////////////////////////////////////////
  // New in 0.4.0
  export default {
    props: ['location'],
    mounted() {
      if (!this.location && navigator.geolocation) {
        let self = this
        navigator.geolocation.getCurrentPosition(function(position) {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          self.location.lat = position.coords.latitude
          self.location.lng = position.coords.longitude
        }, function() {
          console.log("Could not get location")
        });
        } else {
          console.log("No need to fetch a location")
        }
    },
    computed: {
      infoLabel() {
        return I18n.t("map.selector_label")
      }
    },
    data () {
      return {
        untouched: true,
        markers: [{
          position: {
            lat: this.location.lat,
            lng: this.location.lng
            }
        }]
      }
    },
    methods: {
      locationChanged(e) {
        this.untouched = false
        this.location.lat = e.latLng.lat()
        this.location.lng = e.latLng.lng()
      },

      resetMarkerToCenter() {
        this.markers = [
          {
            position: {
              lat: this.location.lat,
              lng: this.location.lng
            }
          }
        ]
      }
    }
  }
</script>
