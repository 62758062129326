import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.observer = new MutationObserver(this.handleMutation.bind(this));
  }

  connect() {
    this.observer.observe(this.getTurboFrame(), {
      attributes: true,
      attributeFilter: ["src"],
    });
  }

  disconnect() {
    this.observer.disconnect();
  }

  getTurboFrame() {
    return document.querySelector(
      "turbo-frame#" + this.element.dataset.turboFrame
    );
  }

  handleMutation() {
    const frameURL = new URL(this.getTurboFrame().src);
    const documentURL = new URL(document.location.href);
    this.getFormFieldNames().forEach((fieldName) => {
      documentURL.searchParams.set(
        fieldName,
        frameURL.searchParams.get(fieldName)
      );
    });

    history.replaceState({}, null, documentURL.href);
  }

  getFormFieldNames() {
    let fieldNames = [];

    this.element
      .querySelectorAll("input[name], select[name], textarea[name]")
      .forEach((element) => fieldNames.push(element.name));

    return fieldNames;
  }
}