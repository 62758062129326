import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sortable", "form"]
  connect() { }

  dragstart(event) {
    let draggableId = event.target.getAttribute("draggable-id")
    event.dataTransfer.setData("application/drag-key", draggableId)
    event.dataTransfer.effectAllowed = "move"
  }

  dragover(event) {
    event.preventDefault()
    return true;
  }

  dragenter(event) {
    event.preventDefault()
  }

  dragleave(event) {
    event.preventDefault()
  }

  highlight(event) {
    if (typeof event.currentTarget.closest == "function") {
      this.sortableTargets.forEach(item => item.classList.remove("highlight-dropzone"))
      let draggable = event.currentTarget.closest(".draggable")
      if (draggable) {
        draggable.classList.add("highlight-dropzone")
      }
    }
  }

  drop(event) {
    var data = event.dataTransfer.getData("application/drag-key")
    const dropTarget = event.target.closest(".draggable")
    if(dropTarget) {
      this.sortableTargets.forEach(item => item.classList.remove("highlight-dropzone"))

      const draggedItem = this.element.querySelector(`[draggable-id='${data}']`);
      const positionComparison = dropTarget.compareDocumentPosition(draggedItem)
      // https: //developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
      if (positionComparison & 4) {
        dropTarget.insertAdjacentElement('beforebegin', draggedItem);
      } else if (positionComparison & 2) {
        dropTarget.insertAdjacentElement('afterend', draggedItem);
      }
    }
    event.preventDefault()
  }

  reorder() {
    let sortOrder = 0
    this.element.querySelectorAll(".draggable input.position").forEach(input => {
      input.value = sortOrder
      sortOrder += 1
    })
  }

  dragend(e) {
    this.reorder()
  }

  autoSubmit(e) {
    setTimeout(() => {
      this.reorder()

      if(this.formTarget && this.formTarget.dataset.remoteDiv) {
        let url = this.formTarget.dataset.url
        let method = this.formTarget.dataset.method
        let parentParam = this.formTarget.dataset.param
        let offset = Number(this.formTarget.dataset.offset || 0)
        let data = {}
        this.sortableTargets.forEach( (sortable, idx) => {
          data[sortable.dataset.modelId] =  { position: offset + idx }
        })

        let body = {}
        body[parentParam] = data

        var token = $('meta[name="csrf-token"]').attr('content');
        fetch(url, {
          method: method,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(body),
          credentials: 'same-origin'
        })

      } else if (this.formTarget && this.formTarget.dataset.remote) {
        Rails.fire(this.formTarget, "submit")
      } else if (this.formTarget) {
        this.formTarget.submit()
      }
    }, 50)
  }
}