import { Controller } from "stimulus"

/*
  This allows a generic behaviour of having a checkbox toggle the disabled or display property of any html element

  <div data-controller="toggle">
    <input type="checkbox" data-target="toggle.control" data-action="toggle#toggle" />
    <input type="text" data-target="toggle.togglable" data-property="disabled" disabled />
    <div data-target="toggle.togglable" data-property="display" style="display:block" >
      Some text I want to hide when the checkbox is clicked
    </div>
  </div>

*/
export default class extends Controller {
  static targets = ["control", "togglable"]

  toggle() {
    this.togglableTargets.forEach(element => {
      switch (element.dataset.property) {
        case "disabled":
          this.toggleDisabled(element)
          break
        case "display":
          this.toggleVisibility(element)
          break
        default:
          return
      }
    })
  }

  toggleDisabled(element) {
    let status = !!element.disabled
    element.disabled = !status

    if (element.disabled) {
      element.setAttribute('readonly', 'readonly')
    } else {
      element.removeAttribute('readonly')
    }

    if (element.dataset.clearvalue){
      element.value = ""
    }
  }

  toggleVisibility(element) {
    if (element.style.display == "none") {
      element.style.display = "block"
    } else {
      element.style.display = "none"
    }
  }
}
