import { Controller } from "stimulus"
import { FetchRequest } from "@rails/request.js"
import throttle from "../../common/throttle"

export default class extends Controller {
  static targets = ["input"]

  initialize() {
    this.updateSection = throttle(this.updateSection, 250)
  }

  static values = {
    observable: Array,
    url: String,
    method: String,
    plural: Boolean,
    inputType: String,
    param: String
  }

  updateSection(e) {
    const params = {}
    if(this.pluralValue && this.inputTypeValue === "checkbox" || this.inputTypeValue === "radio") {
      params[this.paramValue] = this.inputTargets.filter(target => target.checked).map(input => input.value)
    } else if (this.pluralValue) {
      params[this.paramValue] = this.inputTargets.map(input => input.value)
    } else {
      params[this.paramValue] = this.inputTarget.value
    }

    const bodyParams = { body: params }
    const request = new FetchRequest(this.methodValue, this.urlValue, { responseKind: "turbo-stream", ...bodyParams })
    request.perform()
  }
}