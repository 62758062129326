import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button", "content"]

  connect() {
    this.open = true

    if (window.innerWidth <= 800) {
      this.toggle()
    }
  }

  toggle() {
    if (this.open) {
      this.contentTarget.parentNode.classList.add("closed")
      this.buttonTarget.innerHTML = "<"
      this.open = false
    } else {
      this.contentTarget.parentNode.classList.remove("closed")
      this.buttonTarget.innerHTML = ">"
      this.open = true
    }
  }
}