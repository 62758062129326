window.Seabookings = window.Seabookings || {};
Seabookings.AvailabilitySelectionComponent = Seabookings.AvailabilitySelectionComponent || function() {
  var self = {
      resetDateAndSlot: function() {
        $("#summary_reservation_date span").html("<span><i class=\"fa fa-minus\"></i></span>");
        $("#summary_reservation_slot span").html("<span><i class=\"fa fa-minus\"></i></span>");
        $("#reservation_reservation_date").val(null);
        $("#reservation_slot_id").val(null);
      },

      selectDayAndSlot: function(day, slotId, formattedDate) {
        self.selectDay(day, formattedDate);
        self.selectSlot(day, slotId);
      },

      selectDay: function (day, formattedDate) {
        $('.select-availability-section .day.selected').removeClass("selected").addClass("hidden-xs");
        if(day) {
          $('.select-availability-section .day[data-day=' + day + ']').removeClass('hidden-xs').addClass("selected");
        }
        $('#reservation_reservation_date').val(day).trigger('change');
        let elem = document.getElementById("reservation_reservation_date")
        if (elem) {
          elem.dataset.label = formattedDate
          elem.dispatchEvent(new Event('change'));
        }
      },

      selectSlot: function(day, slotId) {
        $(".available-item.selected, .available-item-unavailable.selected, .available-item-full.selected").removeClass("selected");
        let availableItem = $(`#slot-${day}-${slotId}`)
        var time = availableItem.data("time");
        availableItem.addClass("selected");

        $('#reservation_slot_id').val(slotId).trigger('change',  [{ time: time }]);

        let elem = document.getElementById("reservation_slot_id")

        if (elem) {
          elem.dispatchEvent(new CustomEvent('change', {detail: { time: time } }));
        }
      },

      loadingHtml: function() {
        return  "<div class='flex f-row x-align-center' style='padding:2rem;font-size:2rem;width:100%;font-size: 4em;'>" +
          "<i class='fas fa-spinner fa-spin fa-fw'></i>" +
          "</div>";
      },

      setAvailabilityLoading: function(e) {
        $('#availability-week-calendar').html(self.loadingHtml())
      },

      toggleCustomDate: function(e) {
        if(this.checked) {
          $('.time-travel').removeClass('hidden');
          $('#availability-desktop-component').addClass('hidden');
          $('#availability-day-dropdown-wrapper').addClass('hidden');
        } else {
          $('.time-travel').addClass('hidden');
          $('#availability-desktop-component').removeClass('hidden');
          $('#availability-day-dropdown-wrapper').removeClass('hidden');
        }
      },

      timeTravel: function(e) {
        e.preventDefault();
        var reservationDate = $('#time_travel').val();
        if(reservationDate && moment.utc(reservationDate, 'YYYY-MM-DD', true).isValid()) {
          var url = $('.time-travel').data('submit-path') + "?reservation_date=" + reservationDate;
          $('#reservation_slot_id').val(null).trigger('change');
          $('#reservation_reservation_date').val(null).trigger('change');
          $('.custom-date-selection').append(self.loadingHtml());
          $(document).trigger("availability:time-travel");
          $.ajax(url,
            {
              type: 'GET',
              dataType: 'script',
              data: { reservation_date: reservationDate },
              error: function(jqXHR, textStatus, errorThrown) {
              }
            }
          );
        }
      },

      updateSummaryReservationDate: function() {
        var reservationDate = $('#reservation_reservation_date').val();
        if(reservationDate) {
          moment.locale(I18n.locale);
          var formatted = moment(reservationDate).format('dddd, D MMMM YYYY');
          $('#summary_reservation_date').html(formatted);
        } else {
          $('#summary_reservation_date').html('<i class="fa fa-minus">');
        }
      },

      updateSummarySlotId: function(e, data) {
        var slotId =  $('#reservation_slot_id').val();
        if(slotId) {
          $('#summary_reservation_slot span').html(data.time);
        } else {
          $('#summary_reservation_slot span').html('<i class="fa fa-minus">');
        }
      },

      showBookingDetails: function() {
        if ($(this).val()) {
          $('#booking-details-section').removeClass("hidden");
        }
      }
    };

    return self;
  }();

$(document).on("change", '#availability-day-dropdown', function(e) {
  Seabookings.AvailabilitySelectionComponent.resetDateAndSlot();
  $( document ).trigger("availability:change-day", $(e.target).val());
});

$(document).on('click', '.available-item.clickable', function(e) {
  $(document).trigger("availability:select-slot", [$(this).data("date"), $(this).data("slot-id"), $(this).data("formatted-date")]);
});

$(document).on("availability:select-slot", function (e, day, slotId, formattedDate) {
  Seabookings.AvailabilitySelectionComponent.selectDayAndSlot(day, slotId, formattedDate);
});

$(document).on("availability:change-day", function(e, day) {
  Seabookings.AvailabilitySelectionComponent.selectDay(day);
});

$(document).on("availability:time-travel", Seabookings.AvailabilitySelectionComponent.resetDateAndSlot);
$(document).on("change",'.select-availability-section .custom-date-selection .custom-date-selector', Seabookings.AvailabilitySelectionComponent.toggleCustomDate);
$(document).on("click",'#time-travel-button', Seabookings.AvailabilitySelectionComponent.timeTravel);
$(document).on('click', '.select-availability-section .navigation a', Seabookings.AvailabilitySelectionComponent.setAvailabilityLoading);
$(document).on('click', '.select-availability-section .navigation a', function() { $(document).trigger("availability:time-travel") });
$(document).on('change', '#reservation_slot_id', Seabookings.AvailabilitySelectionComponent.showBookingDetails);
