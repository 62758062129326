import Vue from 'vue/dist/vue.esm'
import * as VueGoogleMaps from 'vue2-google-maps'

function init() {
  if (!window.google || !window.google.maps) {
    Vue.use(VueGoogleMaps, {
      load: {
        key: window.GMAPS_KEY,
        libraries: ['places', 'geometry']
      }
    });
  }
}

export default { init }