import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [
    "badge"
  ]

  connect() { }

  updateIcon(e) {
    this.badgeTarget.querySelector("i").classList = `fas fa-${e.srcElement.value}`
  }

  updateTextColor(e) {
    this.badgeTarget.style.color = e.srcElement.value
  }

  updateBackgroundColor(e) {
    this.badgeTarget.style["background-color"] = e.srcElement.value
  }

  updateContent(e) {
    this.badgeTarget.querySelector(".badge-text").innerHTML = e.srcElement.value
  }

}
