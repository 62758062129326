import { Controller } from "stimulus"
import _ from "lodash"

export default class extends Controller {
  static targets = ["country", "activities", "selectCityButton", "cityGroup", "checkbox"]

  initialize() {
    this.recalculateNumberOfSelected = _.debounce(this.recalculateNumberOfSelected, 50)
  }

  connect() { }

  country() {
    return this.countryTarget.options[this.countryTarget.selectedIndex].dataset.code
  }

  activeCities() {
    let result = []
    let country = this.country()

    this.selectCityButtonTargets.forEach(element => {
      if (element.dataset.country == country && element.classList.contains("active")) {
        result.push(element.dataset.city)
      }
    })

    return result;
  }

  clearAllCheckboxes() {
    this.checkboxTargets.forEach(element => {
      element.checked = false
      var event = new Event('change');
      element.dispatchEvent(event);
    })
  }

  clearAllCities() {
    this.selectCityButtonTargets.forEach(element => {
      if (element.classList.contains("active")) {
        element.classList.remove("active")
      }
    })
    this.updateVisibleLocations()
  }

  updateCountry() {
    this.clearAllCheckboxes()
    this.updateVisibleLocations()
  }

  toggleCityActive(e) {
    if (e.target.classList.contains("active")) {
      e.target.classList.remove("active")
    } else {
      e.target.classList.add("active")
    }

    let countryCode = this.country()
    let cities = this.activeCities()
    this.setCityGroupVisible(countryCode, cities)
  }

  updateVisibleLocations() {
    let countryCode = this.country()
    let cities = this.activeCities()
    this.updateVisibilityOfCitySelectors(countryCode)
    this.setCityGroupVisible(countryCode, cities)
  }

  updateVisibilityOfCitySelectors(countryCode) {
    this.selectCityButtonTargets.forEach(element => {
      if (element.dataset.country == countryCode) {
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    })
  }

  setCityGroupVisible(country, cities) {
    this.cityGroupTargets.forEach(element => {
      let isCountrySelected = element.dataset.country == country
      let isCityVisible = cities.length == 0 || cities.includes(element.dataset.city)

      if (isCountrySelected && isCityVisible) {
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    });
  }

  recalculateNumberOfSelected() {

      let counters = this.checkboxTargets.reduce(function (acc, item) {
        var key = item.dataset.city

        acc[key] = acc[key] || [];

        if (item.checked) {
          acc[key].push(item);
        }

        return acc;
      }, {});

      this.selectCityButtonTargets.forEach(button => {
        let numberOfSelectedOnCity = counters[button.dataset.city].length
        let span = button.querySelector("span")

        if (numberOfSelectedOnCity > 0) {
          span.classList.remove("hidden")
          span.textContent = numberOfSelectedOnCity
        } else {
          span.classList.add("hidden")
          span.textContent = null
        }
      })

  }
}