import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.container = document.getElementById(this.data.get("containerId"))
    this.listener = this.element.addEventListener("input", this.sync.bind(this))
    this.sync({ target: { value: this.element.value } })
  }

  disconnect() {
    this.element.removeEventListener("input", this.sync.bind(this))
  }

  sync(event) {
    this.container.innerHTML = event.target.value
  }
}