import { Controller } from "stimulus"

/*

Allows you to show different containers based on the selected value.
Useful for dropdowns or tabs

<select data-controller="dropdown-toggler"
        data-drowpdown-toggler-class="active"
        data-action="change->toggle"
        data-dropdown-toggler-map="value1=#container1,value2=#container2">
  <option value="value1">Value1</option>
  <option value="value2">Value2</option>
</select>
*/
export default class extends Controller {
  initialize() {
    this.toggleClass = this.data.get("class")
    this.togglables = new Map(this.data.get("map").split(",").map(item => item.split("=")))
  }

  toggle(e) {
    let value = e.target.value
    let togglableClass = this.toggleClass

    this.addClass(this.togglables.get(value), this.toggleClass)

    this.togglables.forEach((container, key, map) => {
      if(key !== value && container !== "none") {
        this.removeClass(container, togglableClass)
      }
    })
  }

  addClass(container, clazz) {
    let elem = document.querySelector(container)
    if(elem) {
      elem.classList.add(clazz)
    }
  }

  removeClass(container, clazz) {
    let elem = document.querySelector(container)
    if (elem) {
      elem.classList.remove(clazz)
    }
  }
}