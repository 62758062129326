import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var self = this
    this.element.querySelectorAll("input", "select", "textarea").forEach(elem => {
      elem.addEventListener("input", () => {
        self.dirty = true
      })
    })

    this.element.addEventListener('trix-change', () => {
      self.dirty = true
    })
  }

  preventIfDirty(event) {
    let allow = true;

    if(this.dirty) {
      allow = window.confirm("All changes will be lost if not saved!")
    }

    if (allow == true) {
      //no op
    } else {
      event.preventDefault()
    }
  }
}