import Vue from 'vue/dist/vue.esm'
import GMap from './activity-map.vue'
import _ from 'lodash'
import VueResource from 'vue-resource'
import TurbolinksAdapter from 'vue-turbolinks'

document.addEventListener('turbo:load', () => {
  var element = document.getElementById("gmap")
  if(element) {
    Vue.use(VueResource)
    Vue.use(TurbolinksAdapter)

    function initMap(data) {
      return new Vue({
        el: element,
        components: {
          'gmap': GMap
        },
        data: data,
        watch: {
          "location.street": function(newStreet) {
            this.updateLocation()
          },
          "location.city": function(newStreet) {
            this.updateLocation()
          },
          "location.country": function(newStreet) {
            this.updateLocation()
          }
        },
        methods: {
          canGeocode: function() {
            return this.location.street && this.location.city && this.location.country
          },

          geocode() {
            var geocoder = new google.maps.Geocoder();
            var address = _.join([this.location.street, this.location.city, this.location.country], ",")
            var self = this
            geocoder.geocode({ address: address }, function(results, status) {
              if (status === 'OK') {
                self.location.lat = results[0].geometry.location.lat()
                self.location.lng = results[0].geometry.location.lng()
                self.$refs.map.resetMarkerToCenter()
              } else {
                console.log('Geocode was not successful for the following reason: ' + status);
              }
            })
          },
          updateLocation: _.debounce(function() {
            if(this.canGeocode()) {
              this.geocode()
            }
          }, 350)
        }
      })
    }

    var latitude = element.dataset.lat
    var longitude = element.dataset.lng
    var street = element.dataset.street
    var city = element.dataset.city
    var country = element.dataset.country

    if (latitude && longitude) {
      const data = {
        location: {
          street: street,
          city: city,
          country: country,
          lat: Number(latitude),
          lng: Number(longitude)
        }
      }
      initMap(data)
    } else {
      $.ajax({
        url: "https://geoip-db.com/jsonp",
        jsonpCallback: "callback",
        dataType: "jsonp",
        success: function( location ) {
          const data = {
            location: {
              street: street,
              city: city,
              country: country,
              lat: location.latitude,
              lng: location.longitude
            }
          }
          initMap(data)
        },
        error: function() {
          const data = {
            location: {
              street: street,
              city: city,
              country: country,
              lat: 1.0,
              lng: 1.0
            }
          }
          initMap(data)
        }
      });
    }
  }
})
