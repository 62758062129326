import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "activity",
    "date",
    "slot",
    "reservationId",
    "peopleInput",
    "charterInput",
    "summaryDate",
    "summaryTime",
    "summaryPriceBreakdown",
    "summaryTotal"]


  initialize() {
    this.currency       = this.element.getAttribute("data-currency")
    this.currencySymbol = this.element.getAttribute("data-currency-symbol")
    this.charter        = this.element.getAttribute("data-charter") == "true"
    this.onSale         = this.element.getAttribute("data-on-sale") == "true"

    if (this.onSale) {
      this.salePercentage = parseFloat(this.element.getAttribute("data-sale-percentage"))
    } else {
      this.salePercentage = 0.0
    }
  }

  connect() {
    this.selectedTime = this.summaryTimeTarget.innerHTML
    this.updateSummary()
    document.addEventListener("reservation-form:sync-all", this.updateSummary.bind(this))
  }

  disconnect() {
    document.removeEventListener("reservation-form:sync-all", this.updateSummary.bind(this))
  }

  updateSlot(event) {
    this.selectedTime = event.detail.time
    this.updatePrices()
  }

  updatePrices() {
     const activity_id      = this.activityTarget.value
     const reservation_id   = this.reservationIdTarget.value
     const slot_id          = this.slotTarget.value
     const reservation_date = this.dateTarget.value

     $('#people_per_price_fields').html(
       `
       <div class='price-loading'>
         <i class='fa fa-circle-o-notch fa-spin fa-fw'></i>
       </div>
       `
     );

     if (slot_id) {
       $.ajax('/reservations/update_prices', {
         type: 'GET',
         dataType: 'script',
         data: {
           activity_id:      activity_id,
           slot_id:          slot_id,
           reservation_id:   reservation_id,
           reservation_date: reservation_date
         },

         error: function (jqXHR, textStatus, errorThrown) {},

         success: this.updateSummary.bind(this)
       });
     }
  }

  updateSummary() {
    this.summaryTimeTarget.innerHTML = this.selectedTime || "-"
    this.summaryDateTarget.innerHTML = this.dateTarget.dataset.label || "-"

    this.updatePriceBreakdown()
    this.updateTotals()
  }

  selectedPriceFields() {
    let fields = this.peopleInputTargets.filter(peopleInput => {
      let val = parseInt(peopleInput.value)
      return val != NaN && val > 0
    })

    if (this.charterInputTargets) {
      this.charterInputTargets.filter(charterInput => charterInput.checked).forEach(field => fields.push(field))
    }

    return fields
  }

  updatePriceBreakdown() {
    let html = ""

    this.selectedPriceFields().forEach(priceField => {
      html += `
          <h5>${priceField.dataset.label}</h5>
          <div>
            <div>${this.formatAsCurrency(priceField.dataset.price / 100, priceField.dataset.currency)}</div>
            <span>x ${this.numInstances(priceField)}</span>
          </div>
          `
    })

    this.summaryPriceBreakdownTarget.innerHTML = html
  }

  numInstances(priceField) {
    let numInstances = priceField.value
    if (priceField.dataset.charter) {
      numInstances = 1
    }
    return numInstances
  }

  updateTotals() {
    let total = 0
    let priceFields = this.selectedPriceFields()
    priceFields.forEach(priceField => {
      total += (priceField.dataset.price * this.numInstances(priceField))
    })

    let currency             = priceFields.length > 0 ? priceFields[0].dataset.currency : this.currency
    let discountCents        = total * this.salePercentage / 100
    let totalWithoutDiscount = this.formatAsCurrency(total / 100, currency)
    let totalWithDiscount    = this.formatAsCurrency((total - discountCents) / 100, currency)

    let html = ""
    if (this.onSale) {
      html = `
        <span class="save pr-1">${I18n.t("discount.discount_text", { discount: this.salePercentage } )}</span>
        <span class="discount-applied mr-2">
          ${totalWithoutDiscount}
        </span>
        <span>
          ${totalWithDiscount}
        </span>
      `
    } else {
      html = totalWithoutDiscount
    }

    this.summaryTotalTarget.innerHTML = html
  }

  formatAsCurrency(float_value, currency) {
    return float_value.toLocaleString(window.navigator.language, {
      style: "currency",
      currency: currency
    }).replace(".00", "")
  }
}
