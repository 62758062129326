import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.text = this.data.get("tooltip")
    this.tooltipId = "tooltip-" + new Date().getUTCMilliseconds()
  }

  showTooltip() {
    this.element.insertAdjacentHTML('afterend',
    `<div class="tooltip" id="${this.tooltipId}">${this.text}</div>`)
  }

  hideTooltip() {
    let tooltipElem = document.getElementById(this.tooltipId)
    if(tooltipElem) {
      tooltipElem.parentNode.removeChild(tooltipElem)
    }
  }
}