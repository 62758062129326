import _ from 'lodash'
import Rails from "@rails/ujs"

let autoSubmit = _.debounce(function (e) {
  if (e.target !== this) {
    return;
  }

  let form = $(e.target).closest('form')

  if (!form) {
    return false
  }

  if ($(form).data("remote")) {
    Rails.fire(form[0], "submit");
  } else {
    $(form).submit();
  }
}, 250);

function init() {
  $('.auto-submit').on("change", autoSubmit)
  $(document).on("keyup", ".input-auto-submit", autoSubmit)
}

function initOnce() {
  $(document).on("select2:select", ".auto-submit", autoSubmit);
}

export default { init, initOnce }
